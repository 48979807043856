import "./App.css";
import logo from "./FamilyLogo.png";

function App() {
  return (
    <div className="App">
      <img src={logo} className="logo" />
    </div>
  );
}

export default App;
